import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';
import { AuthService } from './../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.isUserLoggedIn().then((res) => {
        if(res) {
          return true;
        } else {
          this.router.navigate(['/login'])
          return false;
        }
      });
      /*
      return this.authService.isUserLoggedIn.pipe(map((response: boolean) => {
        if (response) {
          return true;
        }
        
        this.router.navigate(['/login']);
        return false;
      }), catchError((error) => {
        this.router.navigate(['/login']);
        return of(false);
      }));
      */
  }

}
