import { Component } from '@angular/core';
import { ScrollBarStyleDark } from './shared/styles';
import { Storage } from '@ionic/storage-angular';
import * as Parse from 'parse';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AppConfig } from './app-config';

import { AuthService} from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  navSections:any = [
    {
      section: null,
      pages: [
        {title: 'Dashboard', icon: 'pie-chart', link: '/dashboard'}
      ]
    },
    {
      section: "Analytics",
      pages: [
        {title: 'Store', icon: 'storefront', link: '/store'},
        {title: 'Reports', icon: 'document', link: '/reports'}
      ]
    },
    {
      section: "Management",
      pages: [
        {title: 'Leads', icon: 'golf', link: '/leads'},
        {title: 'Workflow', icon: 'git-merge', link: '/workflow'}
      ]
    },
    {
      section: "Communication",
      pages: [
        {title: 'Forum', icon: 'albums'},
        {title: 'Chat', icon: 'chatbubbles'},
        {title: 'Calendar', icon: 'calendar'},
        {title: 'Email', icon: 'mail'}
      ]
    },
    {
      section: "Resources",
      pages: [
        {title: 'Catalog & Sales', icon: 'file-tray-stacked'}
      ]
    }
  ];

  scrollBarStyle: String = ScrollBarStyleDark;
  isUserLoggedIn: boolean = false;

  constructor(
    private authService: AuthService,
    private storage: Storage,
    private router: Router,
    private menuController: MenuController
  ) {
    Parse.initialize(AppConfig.APP_ID, AppConfig.JS_KEY);
    (Parse as any).serverURL = AppConfig.SERVER_URL;
  }

  visitPage(pageURL) {
    if(pageURL) {
      this.menuController.close();
      this.router.navigate([pageURL]).then((res) => {
        if(res) {

        }
      });
    }
  }

  async ngOnInit() {
    await this.storage.create();
    this.authService.currentLoginStatus.subscribe(res => {
      this.isUserLoggedIn = res;
    }, err => {
      this.isUserLoggedIn = false;
    });
  }
}
