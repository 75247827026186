const ScrollBarStyleDark = `
    @media(pointer: fine) {
        ::-webkit-scrollbar {
            width: 7px;
        }
        ::-webkit-scrollbar-track {
            background: var(--ion-color-primary-shade);
        }
        ::-webkit-scrollbar-thumb {
            background: var(--ion-color-primary-tint);
        }
    }
`;

const ScrollBarStyleLight = `
    @media(pointer: fine) {
        ::-webkit-scrollbar {
            width: 7px;
        }
        ::-webkit-scrollbar-track {
            background: var(--ion-color-light-shade);
        }
        ::-webkit-scrollbar-thumb {
            background: var(--ion-color-primary-tint);
        }
    }
`;

export { ScrollBarStyleDark, ScrollBarStyleLight };